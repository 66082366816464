import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'

export default function LangButton () {
  const { t, i18n } = useTranslation()

  return (
    <Button
    className='langbtn'
      onClick={() =>
        i18n.changeLanguage(
          i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru'
        )
      }
      appearance="primary"
    >
      {i18n.language.toLocaleUpperCase()}
    </Button>
  )
}

import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { $authHost, $host } from './index'
import { Alert } from 'rsuite'

export const regUser = async (props) => {
  const data = await $host.post('/accounts/users/', props)
  return data
}

export const authUser = async (props) => {
  try {
    const data = await $host.post('/token/', props)
    const user = jwt_decode(data.data.access)
    Cookies.set('token', data.data.access)
    Cookies.set('refresh', data.data.refresh)
    Cookies.set('user_id', user.user_id)
    getOwnData()
    return data
  } catch (e) {
    Alert.error(Object.values(e.response.data))
  }
}

export const getOwnData = async () => {
  const data = await $authHost.get('/accounts/users/' + Cookies.get('user_id') + '/')
  Cookies.set('user', JSON.stringify(data.data))
  return data
}

export const refresh = async () => {
  const data = await $authHost.post('/token/refresh/', { refresh: Cookies.get('refresh') })
    .then(r => {
      Cookies.set('token', r.data.access)
      getOwnData()
    })
  return data
}

export const validate = async () => {
  const data = await $authHost.post('/token/verify/', { token: Cookies.get('token') })
    .then(_ => {
      refresh()
    })
    .catch(_ => {
      Cookies.remove('token')
      Cookies.remove('refresh')
      Cookies.remove('user_id')
      Cookies.remove('user')
      window.location.reload()
    })
  return data
}

export const getCategories = async () => {
  const data = await $authHost.get('/contents/categories/')
  return data
}

export const deleteCategory = async (id) => {
  const data = await $authHost.delete(`/contents/categories/${id}/`)
  return data
}

export const addCategory = async (props) => {
  const data = await $authHost.post('/contents/categories/', props)
  return data
}

export const getPosts = async () => {
  const data = await $authHost.get('/contents/posts/')
  return data
}

export const deletePost = async (id) => {
  const data = await $authHost.delete(`/contents/categories/${id}/`)
  return data
}

export const addPost = async (props) => {
  const data = await $authHost.post('/contents/posts/', props)
  return data
}

export const getGalleryImages = async (page, limit) => {
  const response = await $authHost.get('/contents/images/', {
    params: {
      caption: 'GALLERY-DEFAULT',
      limit,
      offset: limit * page
    }
  })
  return response.data
}

export const getPointImages = async (point) => {
  const response = await $authHost.get('/contents/images/', {
    params: {
      caption: 'GALLERY-POINT-' + point
    }
  })
  return response.data
}

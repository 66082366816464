import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const SideBase = (props) => {
  const { t, i18n } = useTranslation()
  return (
    <div id="sidebar" className="open">
      <div className="un-list">
          <img src={`goals/13-${t('common.lang')}.png`}/>
          <img src={`goals/15-${t('common.lang')}.png`}/>
      </div>
        <div className="tab-content">
                  {props.list}
        </div>
    </div>)
}

export default SideBase

import { Link } from 'react-router-dom/cjs/react-router-dom'
import SideBase from '../SideBase'
import CloseButton from '../CloseButton'
import { useTranslation } from 'react-i18next'

const GenericSide = ({ hidden, hide, title, tiles, ext }) => {
  const { t, i18n } = useTranslation()
  return (
    <SideBase
      list={
        <>
          <h2 className='side-title'>{title} <CloseButton hidden={hidden} hide={hide} /></h2>
          <div id="catalog" className="tab-pane active">
            <div className="ng-isolate-scope">
              <div
                id="themes-content"
                className="themes-switcher collapse in"
                aria-expanded="true"
              >
                <ul className="themes-list database">
                  {tiles.map(ti =>
                    <li key={ti.name} className={`theme-${ti.style}`} style={{ backgroundColor: ti.color || '' }}>
                      <Link style={{ fontSize: '15px' }} to={ti.to}>
                        {t(ti.name)}
                      </Link>
                    </li>
                  )}
                  {ext || <></>}
                </ul>
              </div>
            </div>
          </div>
        </>
      }
    />
  )
}

export default GenericSide

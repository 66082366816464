import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Modal } from 'rsuite'
import { getGalleryImages } from '../../http/API'
import GenericPage from '../components/generic/GenericPage'
import tiles from '../components/generic/Tiles'

const MapFImgs = () => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [page, setPage] = useState(0)
  const [selImage, selectImage] = useState()
  const [total, setTotal] = useState()
  const [limit, setLimit] = useState(104)

  useEffect(() => {
    try {
      setLoading(true)
      getGalleryImages(page, limit).then((res) => {
        setLoading(false)
        setImages(res.results)
        setTotal(res.count)
      })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }, [page, limit])

  const fixLink = (link) => {
    return link.replace('http://localhost:8000/', 'https://dskgis.kaznu.kz/')
  }

  const ext = (
    <div id="gallery">
      <div>
        {images.map((img, key) => (
          <div key={key}>
            <img
              width={150}
              height={100}
              onClick={() => selectImage(fixLink(img.image))}
              src={fixLink(img.thumbnail)}
            />
          </div>
        ))}
      </div>
      <Modal
        className="gal-modal"
        size="full"
        show={selImage}
        onHide={() => selectImage(null)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <img
            style={{ objectFit: 'contain', maxHeight: '80vh' }}
            src={selImage}
          />
          <Modal.Footer>
            <Button onClick={() => open(selImage)}>
              {t('gal.open_new')} </Button>
            <Button appearance="primary" onClick={() => selectImage(null)}>
              {t('common.close')} </Button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* <Pagination
      prev
      last
      next
      first
      size="lg"
      pages={Math.ceil(total / limit)}
      activePage={page}
      onSelect={setPage}
    /> */}
    </div>
  )

  return (
    <GenericPage
      title={t('tiles.field.img')}
      tiles={tiles.back('field')}
      frame_offset={0}
      insert={ext}
    />
  )
}
export default MapFImgs

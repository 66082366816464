import CloseIcon from '@rsuite/icons/Close'
import TaskIcon from '@rsuite/icons/Task'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'rsuite'

export default function CloseButton ({ hidden, hide }) {
  const { t, i18n } = useTranslation()

  return (
    <IconButton id='hideBTN' icon={!hidden ? <CloseIcon /> : <TaskIcon />} onClick={() => hide(p => !p)}>{hidden && t('common.open_panel')}</IconButton>
  )
}

import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import { range } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'rsuite'
import MapControls from '../../map/MapControls'
import GenericPage from '../components/generic/GenericPage'
import tiles from '../components/generic/Tiles'

const MapMeteo = () => {
  const [mapType, setMap] = useState(
    'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
  )

  const { t, i18n } = useTranslation()

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  const [tempXLSX, setTempXLSX] = useState()
  const [precipXLSX, setPrecipXLSX] = useState()
  const [selType, setType] = useState()
  const [selName, setName] = useState()

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control
        .attribution({
          position: 'bottomright'
        })
        .addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      const topPane = mapRef?.current.createPane(
        'leaflet-top-pane',
        mapRef.current.getPanes().mapPane
      )
      paneRef.current = topPane

      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {}
  }, [])
  useEffect(() => {
    mapRef.current.attributionControl.setPrefix('')
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])

  useEffect(() => {
    fetch('./temp.json').then(async (e) => {
      setTempXLSX(await e.json())
    })
    fetch('./precip.json').then(async (e) => {
      setPrecipXLSX(await e.json())
    })
    searchGeometryRef.current.clearLayers()
    fetch('../points_meteo.json').then(async (response) => {
      const json = await response.json()
      json.features.forEach((f, key) => {
        const mask = L.geoJSON(f)
        const name = t('meteopoints.p' + key)
        mask.bindTooltip(name)

        mask.addEventListener('mouseover', () => mask.openTooltip())

        mask.addEventListener('click', () => {
          setName(f.properties.Name)
          !selType && setType('temp')
        })
        searchGeometryRef.current.addLayer(mask)
      })
    })
  }, [])

  return (
    <>
      <GenericPage
        mapRef={mapRef}
        title={t('tiles.db.meteo')}
        tiles={tiles.back('db')}
      />
      {selName && (
        <div className="xlsx-viewer">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <p>
              <Button>{t('meteo.station')} {selName}</Button>
            </p>
            <p>
              <Button
                appearance={selType === 'temp' ? 'primary' : 'default'}
                onClick={() => setType('temp')}
              >
                {t('meteo.temp')} </Button>
              &nbsp;/&nbsp;
              <Button
                appearance={selType === 'precip' ? 'primary' : 'default'}
                onClick={() => setType('precip')}
              >
                {t('meteo.percip')} </Button>
            </p>
          </div>
          {selType === 'temp'
            ? (
            <Table
              data={tempXLSX[selName]}
              height={250}
              // affixHeader
            >
              <Table.Column flexGrow={1} align="center">
                <Table.HeaderCell>{t('common.year')}</Table.HeaderCell>
                <Table.Cell dataKey="Год/Годы" />
              </Table.Column>
              {range(1, 13).map((i) => (
                <Table.Column flexGrow={1} key={i}>
                  <Table.HeaderCell>{i}</Table.HeaderCell>
                  <Table.Cell dataKey={String(i)} />
                </Table.Column>
              ))}
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('meteo.winter')}</Table.HeaderCell>
                <Table.Cell dataKey="Зима" />
              </Table.Column>
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('meteo.spring')}</Table.HeaderCell>
                <Table.Cell dataKey="Весна" />
              </Table.Column>
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('meteo.summer')}</Table.HeaderCell>
                <Table.Cell dataKey="Лето" />
              </Table.Column>
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('meteo.fall')}</Table.HeaderCell>
                <Table.Cell dataKey="Осень" />
              </Table.Column>
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('common.year')}</Table.HeaderCell>
                <Table.Cell dataKey="Год (календ)" />
              </Table.Column>
              <Table.Column flexGrow={1} align="center">
                <Table.HeaderCell>{t('meteo.hp')}</Table.HeaderCell>
                <Table.Cell dataKey="ХП" />
              </Table.Column>
              <Table.Column flexGrow={2} align="center">
                <Table.HeaderCell>{t('meteo.tp')}</Table.HeaderCell>
                <Table.Cell dataKey="ТП" />
              </Table.Column>
            </Table>
              )
            : (
                selType === 'precip' && (
              <Table data={precipXLSX[selName]} height={250}>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('common.year')}</Table.HeaderCell>
                  <Table.Cell dataKey="Год" />
                </Table.Column>
                {range(1, 13).map((i) => (
                  <Table.Column flexGrow={1} key={i}>
                    <Table.HeaderCell>{i}</Table.HeaderCell>
                    <Table.Cell dataKey={String(i)} />
                  </Table.Column>
                ))}
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.daf')}</Table.HeaderCell>
                  <Table.Cell dataKey="ДЯФ" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.mam')}</Table.HeaderCell>
                  <Table.Cell dataKey="МАМ" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.iia')}</Table.HeaderCell>
                  <Table.Cell dataKey="ИИА" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.son')}</Table.HeaderCell>
                  <Table.Cell dataKey="СОН" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('common.year')}</Table.HeaderCell>
                  <Table.Cell dataKey="ГОД (янв-дек)" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.hp')}</Table.HeaderCell>
                  <Table.Cell dataKey="ХП" />
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                  <Table.HeaderCell>{t('meteo.tp')}</Table.HeaderCell>
                  <Table.Cell dataKey="ТП" />
                </Table.Column>
              </Table>
                )
              )}
        </div>
      )}
    </>
  )
}
export default MapMeteo

import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MapControls from '../../map/MapControls'

import GenericPage from '../components/generic/GenericPage'
import tiles from '../components/generic/Tiles'
import { getGalleryImages, getPointImages } from '../../http/API'
import { Alert, Button, Modal } from 'rsuite'

const MapFPoint = () => {
  const [mapType, setMap] = useState(
    'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
  )
  const [selPoint, selectPoint] = useState()

  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control
        .attribution({
          position: 'bottomright'
        })
        .addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      const topPane = mapRef?.current.createPane(
        'leaflet-top-pane',
        mapRef.current.getPanes().mapPane
      )
      paneRef.current = topPane
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {}
  }, [])
  useEffect(() => {
    mapRef.current.attributionControl.setPrefix('')
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])

  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    fetch('../points.json').then(async (response) => {
      const json = await response.json()
      console.log(json)
      json.features.forEach((f) => {
        const mask = L.geoJSON(f)
        const name = f.properties.name || t('common.unknown')
        mask.bindTooltip(name, { permanent: true })

        mask.addEventListener('mouseover', () => mask.openTooltip())

        mask.addEventListener('click', () => selectPoint(f.properties.name))

        searchGeometryRef.current.addLayer(mask)
      })
    })
  }, [])

  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [page, setPage] = useState(0)
  const [selImage, selectImage] = useState()
  const [total, setTotal] = useState()
  const [limit, setLimit] = useState(104)

  useEffect(() => {
    try {
      if (selPoint) {
        setLoading(true)
        getPointImages(selPoint).then((res) => {
          setLoading(false)
          setImages(res.results)
          setTotal(res.count)
        })
      }
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }, [selPoint])

  const fixLink = (link) => {
    return link.replace('http://localhost:8000/', 'https://dskgis.kaznu.kz/')
  }

  const ext = (
    <div id="gallery">
      <Modal
        show={selPoint}
        onHide={() => selectPoint(null)}
        style={{ maxWidth: '100vw' }}
      >
        <div id="gallery">
          <h3>{selPoint}</h3>
          <div>
            {images.map((img, key) => (
              <div key={selPoint + key}>
                <img
                  width={150}
                  height={100}
                  onClick={() => selectImage(fixLink(img.image))}
                  src={fixLink(img.thumbnail)}
                />
              </div>
            ))}
          </div>
          <Button appearance="primary" onClick={() => selectPoint(null)}>
            {t('common.close')} </Button>
        </div>
      </Modal>
      <Modal
        className="gal-modal"
        size="full"
        show={selImage}
        onHide={() => selectImage(null)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <img
            style={{ objectFit: 'contain', maxHeight: '80vh' }}
            src={selImage}
          />
          <Modal.Footer>
            <Button onClick={() => open(selImage)}>
              {t('gal.open_new')} </Button>
            <Button appearance="primary" onClick={() => selectImage(null)}>
              {t('common.close')} </Button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* <Pagination
      prev
      last
      next
      first
      size="lg"
      pages={Math.ceil(total / limit)}
      activePage={page}
      onSelect={setPage}
    /> */}
    </div>
  )

  return (
    <GenericPage
      title={t('tiles.field.points')}
      mapRef={mapRef}
      tiles={tiles.back('field')}
      insert={ext}
    />
  )
}

export default MapFPoint

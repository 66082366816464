import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button, Divider } from 'rsuite'
import LangButton from '../components/generic/LangButton'

const DBFarm = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to="/db" className="home">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
      <LangButton />
      <div className="title-wrapper">
        <h1 className="title-h1">{t('tiles.db.farm')}</h1>
      </div>
      <hr />
      <div className="about-text db-farm">
        <img src={`farm/${t('common.lang')}/1.png`} />
        <p>
          {t('farm.p1')} </p>

        <img src={`farm/${t('common.lang')}/2.png`} />
        <p>
          {t('farm.p2')} </p>

        <img src={`farm/${t('common.lang')}/3.png`} />
        <p>
          {t('farm.p3')} </p>

        <img src={`farm/${t('common.lang')}/4.png`} />
        <p>
          {t('farm.p4')} </p>

        <img src={`farm/${t('common.lang')}/5.png`} />
        <p>
          {t('farm.p5')} </p>

        <img src={`farm/${t('common.lang')}/6.png`} />
        <p>
          {t('farm.p6')} </p>

        <img src={`farm/${t('common.lang')}/7.png`} />
        <p>{t('farm.p7')}</p>

        <img src={`farm/${t('common.lang')}/8.png`} />
        <p>
          {t('farm.p8')} </p>

        <div className="half-image">
          <img src={`farm/${t('common.lang')}/9.png`} />
          <img src={`farm/${t('common.lang')}/10.png`} />
        </div>
        <p>
          {t('farm.p9')} </p>

        <img src={`farm/${t('common.lang')}/11.png`} />
        <p>{t('farm.p10')}</p>

        <div className="half-image">
          <img src={`farm/${t('common.lang')}/12.png`} />
          <img src={`farm/${t('common.lang')}/13.png`} />
        </div>
        <p>
          {t('farm.p11')} </p>

        <img src={`farm/${t('common.lang')}/14.png`} />
        <p>
          {t('farm.p12')} </p>
      </div>
    </div>
  )
}

export default DBFarm

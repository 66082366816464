import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Auth from '../Auth'
import CloseButton from './CloseButton'

const Header = ({ hidden, hide }) => {
  const [openAuth, setOpenAuth] = useState(false)
  const { t, i18n } = useTranslation()

  return (
<>
<header
        className="navbar navbar-default navbar-fixed-top"
        role="navigation"
      >
        <div className="container-fluid">
          <div className="navbar-inner">
            <div className="pull-left brand">
              <p style={{ fontSize: 23 }}>
                <img onClick={() => hide(e => !e)} src="logo1.png" alt="" height='55px' style={{ margin: '0 10px' }} />
                <span className='name'>{t('title.main')}</span>
              </p>
            </div>
          </div>
          <ul className="nav navbar-nav pull-right" style={{ display: 'flex', alignItems: 'center' }}>
            <li className="hidden-sm">
              { hidden
                ? <CloseButton hidden={hidden} hide = {hide} />
                : <Link
                to="/about"
                style={{ fontSize: '1.3em', height: '100%', borderLeft: '1px solid black', textIndent: 0, padding: '18px 10px 10px 10px' }}
              >
                {t('common.about')} </Link> }
            </li>
            <li className="lang icon">
              <a onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} className="ng-pristine ng-untouched ng-valid ng-not-empty" style={{ textIndent: 40, width: 75, textAlign: 'left', display: 'flex', alignItems: 'center', height: 50, padding: 0, cursor: 'pointer', userSelect: 'none' }}>
                <span style={{ fontSize: 30 }}>{i18n.language.toLocaleUpperCase()}</span>
              </a>

            </li>

            <li className="user icon" onClick={() => setOpenAuth(true)}>
              <a className="ng-pristine ng-untouched ng-valid ng-not-empty">
                User
              </a>
            </li>
          </ul>

        </div>
      </header>
{openAuth && <Auth close={() => setOpenAuth(false)} />}
</>
  )
}

export default Header

import { useTranslation } from 'react-i18next'
import GenericPage from '../../components/generic/GenericPage'
import tiles from '../../components/generic/Tiles'

const MapSentinel = () => {
  const { t, i18n } = useTranslation()
  const ext = <p className='mobile-warn'>{t('common.mobile-warn')}</p>
  return (
    <GenericPage frame="https://rsgiskaznu.maps.arcgis.com/apps/mapviewer/index.html?webmap=628916311d2047d38e01dcfe2996903c" title={t('tiles.res-lu.sentinel')} tiles={tiles.back('res-lu')} frame_offset={60} ext={ext}/>
  )
}
export default MapSentinel

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as API from '../http/API'
import { Alert, Button, Input, Modal, SelectPicker } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import RichTextEditor from 'react-rte'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import LangButton from './components/generic/LangButton'

const SciBase = () => {
  const { t, i18n } = useTranslation()

  const [ukey, update] = useState(0)
  const [postList, setPosts] = useState()
  const [categories, setCategories] = useState()
  const [newCat, setNewCat] = useState()
  const [dataPub, setDataPub] = useState({
    contentRTF: RichTextEditor.createEmptyValue()
  })
  const [open, setOpen] = useState(false)
  const [NCModal, setNCModal] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const fetchNews = () => {
    try {
      API.getCategories()
        .then((r) => {
          API.getPosts().then((p) => {
            setPosts(p.data.results)
          })
          const arr = r.data.results.reduce((cat, item) => {
            cat[item.id] = item
            return cat
          }, {})
          console.log(arr)
          console.log(r.data.results)
          arr[-1] = { id: -1, name: '+ ' + t('scibase.newcat') }
          setCategories(arr)
        })
        .catch((e) => {
          console.log(e)
        })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }
  const publishNews = () => {
    try {
      dataPub.content = dataPub?.contentRTF.toString('html')
      API.addPost(dataPub)
        .then((r) => {
          setDataPub({ contentRTF: RichTextEditor.createEmptyValue() })
          handleClose()
          Alert.success(t('scibase.newspublished'))
          update(ukey + 1)
        })
        .catch((e) => {
          console.log(e)
        })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }
  const saveNewCat = () => {
    try {
      API.addCategory(newCat)
        .then((r) => {
          setNewCat(null)
          setNCModal(false)
          Alert.success(t('scibase.catsaved'))
          update(ukey + 1)
        })
        .catch((e) => {
          console.log(e)
        })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }

  useEffect(() => {
    fetchNews()
  }, [ukey])

  const changeCategory = (newCat) => {
    if (newCat === -1) {
      setNCModal(true)
      setDataPub({ ...dataPub, category: null })
    } else {
      setDataPub({ ...dataPub, category: newCat })
    }
  }

  return (
    <div>
      <Link to="/" className="home">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
      <LangButton />
      <div className="title-wrapper">
        <h1 className="title-h1">{t('scibase.title')}</h1>
      </div>
      <hr />
      <div className="news-wrap">
        <Button onClick={handleOpen}>
          <h5>+ {t('scibase.publish')}</h5>
        </Button>
        <br />
        {postList?.map((n) => (
          <div key={n?.title} className="news-item">
            <h4>{n.title}</h4>
            <hr />
            <RichTextEditor
              readOnly
              value={RichTextEditor.createValueFromString(n.content, 'html')}
            />
            {/* <hr />
            <h6>Вложения:</h6>
            <ul>
              {n.attachments.map((attachment, index) => (
                <li key={index}>
                  <a href={attachment.blob} download={attachment.name}>{attachment.name}</a>
                </li>
              ))}
            </ul> */}
            {n.category && (
              <p>
                {t('scibase.category')}: {categories[n?.category].name}
              </p>
            )}
            <p>
              {t('scibase.author')}: {n?.author}
            </p>
          </div>
        ))}
      </div>
      <Modal show={open} onHide={handleClose} className="auth-modal">
        <Modal.Header>
          <Modal.Title>{t('scibase.entry')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            placeholder={t('scibase.heading')}
            value={dataPub?.title}
            onChange={(e) => setDataPub({ ...dataPub, title: e.slice(0, 80) })}
          />
          {categories && (
            <SelectPicker
              searchable={false}
              placeholder={t('scibase.category')}
              data={Object.values(categories).map((e) => ({
                value: e.id,
                label: e.name
              }))}
              value={dataPub?.category}
              onChange={changeCategory}
              style={{ width: '100%' }}
            />
          )}
          {/* <Input componentClass="textarea" rows={5} placeholder="Содержание (до 1000 символов)" value={dataPub?.content} onChange={e => setDataPub({ ...dataPub, content: e.slice(0, 1000) })}/> */}
          <RichTextEditor
            value={dataPub?.contentRTF}
            onChange={(e) => setDataPub({ ...dataPub, contentRTF: e })}
            placeholder={t('scibase.content')}
            style={{
              height: 400
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={publishNews} appearance="primary">
            {t('scibase.publish')}{' '}
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            {t('common.cancel')}{' '}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={NCModal}
        onHide={() => setNCModal(false)}
        className="auth-modal"
      >
        <Modal.Header>
          <Modal.Title>{t('scibase.newcat')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            placeholder={t('scibase.name')}
            value={newCat?.name}
            onChange={(e) => setNewCat({ ...newCat, name: e })}
          />
          <Input
            placeholder={t('scibase.desc')}
            value={newCat?.description}
            onChange={(e) => setNewCat({ ...newCat, description: e })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveNewCat} appearance="primary">
            {t('common.save')}{' '}
          </Button>
          <Button onClick={() => setNCModal(false)} appearance="subtle">
            {t('common.cancel')}{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SciBase

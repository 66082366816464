import { useEffect, useState } from 'react'
import Header from '../Header'
import GenericSide from './GenericSide'

const GenericPage = ({ mapRef, frame, tiles, title, ext, frame_offset, insert }) => {
  const [hiddenSB, hideSB] = useState(false)

  useEffect(() => {
    hiddenSB && mapRef && mapRef.current.invalidateSize()
  }, [hiddenSB])

  return (
    <div
      data-theme="main"
      className="lux-body no-touch full"
      style={{ cursor: 'auto' }}
    >
      <Header hide={hideSB} hidden={hiddenSB} />
      <div id="main-container">
        {!hiddenSB && <GenericSide hide={hideSB} hidden={hiddenSB} tiles={tiles} title={title} ext={ext}/>}
        <div className="map-wrapper">
          <app-map className="ng-isolate-scope">
            <div
              className="app-map mapboxgl-map blankBackground"
              style={{ cursor: 'default' }}
            >
              {frame && <iframe style={{
                height: `calc(100% + ${frame_offset}px`,
                bottom: frame_offset
              }} src={frame} />}
              {mapRef && <div
                className="ol-viewport"
                style=
                {{
                  position: 'relative',
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%'
                }}
                id='map'
              />}
              {insert || <></>}
            </div>
          </app-map>
        </div>
      </div>
    </div>
  )
}
export default GenericPage

import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'
import LangButton from './components/generic/LangButton'

const About = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to="/" className="home">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
        <LangButton />
      <div className="title-wrapper">
        <h1 className="title-h1">{t('common.about')}</h1>
      </div>
      <hr />
      <div className="about-text">
        <Trans i18nKey="about" />
      </div>
    </div>
  )
}

export default About

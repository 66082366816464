const t = (a) => a

const tiles = {
  main: [
    {
      name: t('tiles.main.scibase'),
      to: '/scibase',
      font: 15,
      style: 'tourisme'
    },
    {
      name: t('tiles.main.db'),
      to: '/db',
      font: 15,
      style: 'main'
    },
    {
      name: t('tiles.main.sensing'),
      to: '/sensing',
      font: 15,
      style: 'geosciences'
    },
    {
      name: t('tiles.main.field'),
      to: '/field',
      font: 15,
      style: 'emwelt'
    },
    {
      name: t('tiles.main.map'),
      to: '/map',
      font: 15,
      style: 'cadastre_hertzien'
    },
    {
      name: t('tiles.main.recs'),
      to: '/recs',
      font: 15,
      style: 'urban_farming'
    },
    {
      name: t('tiles.main.sem'),
      to: '/sem',
      font: 15,
      style: 'at'
    }
  ],
  db: [
    {
      name: t('tiles.back'),
      to: '/',
      style: 'return'
    },
    {
      name: t('tiles.db.hydro'),
      to: '/db-hydro',
      style: 'pag'
    },
    {
      name: t('tiles.db.meteo'),
      to: '/db-meteo',
      font: 21,
      style: 'atlas_demographique'
    },
    {
      name: t('tiles.db.farm'),
      to: '/db-farm',
      style: 'agriculture'
    }
  ],
  hydro: [
    {
      name: t('tiles.back'),
      to: '/db',
      style: 'return'
    },
    {
      name: t('tiles.db-hydro.posts'),
      to: '/db-hydro-posts',
      style: 'pag',
      font: 23
    },
    {
      name: t('tiles.db-hydro.bass'),
      to: '/db-hydro-bass',
      font: 18,
      style: 'pag'
    }
  ],
  recs: [
    {
      name: t('tiles.back'),
      to: '/',
      style: 'return'
    },
    {
      name: t('tiles.recs.1'),
      to: '/recs-1',
      style: 'urban_farming',
      font: 23
    },
    {
      name: t('tiles.recs.2'),
      to: '/recs-2',
      style: 'urban_farming',
      font: 20
    },
    {
      name: t('tiles.recs.3'),
      to: '/recs-3',
      style: 'urban_farming',
      font: 20
    }
  ],
  res: [
    {
      name: t('tiles.back'),
      to: '/',
      style: 'return'
    },
    // {
    //   name: 'GEE',
    //   to: '/gee',
    //   style: 'at'
    // },
    {
      name: t('tiles.res.indices'),
      to: '/res-indices',
      style: 'at'
    },
    {
      name: t('tiles.res.lu'),
      to: '/res-lu',
      style: 'lenoz',
      font: 20
    }
  ],
  field: [
    {
      name: t('tiles.back'),
      to: '/',
      style: 'return'
    },
    {
      name: t('tiles.field.img'),
      to: '/field-img',
      style: 'main',
      font: 24
    },
    {
      name: t('tiles.field.points'),
      to: '/field-points',
      style: 'lenoz'
    }
  ],
  mapping: [
    {
      name: t('tiles.back'),
      to: '/',
      style: 'return',
      color: 'rgb(25 87 0)'
    },
    {
      name: t('tiles.map.ls'),
      to: '/map-ls',
      style: 'return',
      font: 19,
      color: 'rgb(32 111 0)'
    },
    {
      name: t('tiles.map.nc'),
      to: '/map-nc',
      style: 'return',
      font: 20,
      color: 'rgb(35 122 0)'
    },
    {
      name: t('tiles.map.np'),
      to: '/map-np',
      style: 'return',
      font: 15,
      color: 'rgb(41 143 0)'
    },
    {
      name: t('tiles.map.rn'),
      to: '/map-rn',
      style: 'return',
      font: 15,
      color: 'rgb(46 161 0)'
    },
    {
      name: t('tiles.map.fs'),
      to: '/map-fs',
      style: 'return',
      font: 15,
      color: 'rgb(51 180 0)'
    },
    {
      name: t('tiles.map.dd'),
      to: '/map-dd',
      style: 'return',
      font: 25,
      color: 'rgb(59 206 0)'
    }
  ],
  landuse: [
    {
      name: t('tiles.back'),
      to: '/sensing',
      style: 'return'
    },
    {
      name: t('tiles.res-lu.sentinel'),
      to: '/res-lu-sentinel',
      style: 'main'
    },
    {
      name: t('tiles.res-lu.landsat'),
      to: '/res-lu-landsat',
      style: 'main'
    }
  ],
  back: (where) => [{
    name: t('tiles.back'),
    to: `/${where}`,
    style: 'return'
  }]
}

export default tiles

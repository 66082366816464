import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'
import LangButton from '../components/generic/LangButton'

const Recs1 = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to="/recs" className="home">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
        <LangButton />
      <div className="title-wrapper">
        <h1 className="title-h1">{t('tiles.recs.1')}</h1>
      </div>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: t('recs.1') }} />
    </div>
  )
}

export default Recs1

import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'
import LangButton from './components/generic/LangButton'

const Seminar = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to="/" className="home">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
      <LangButton />
      <div className="title-wrapper">
        <h1 className="title-h1">{t('tiles.main.sem')}</h1>
      </div>
      <hr />
      <div className="about-text db-farm">
        <img src="seminar/Picture1.png" />
        <p>
          {t('seminar.p1')} </p>

        <img src="seminar/Picture2.png" />
        <p>
          {t('seminar.p2')} </p>

        <img src="seminar/Picture3.png" />
        <p>
          {t('seminar.p3')} </p>

        <img src="seminar/Picture4.png" />
        <p>
          {t('seminar.p4')} </p>

        <img src="seminar/Picture5.png" />
        <p>
          {t('seminar.p5')} </p>
      </div>
    </div>
  )
}

export default Seminar
